.coming-soon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.flex-row.action-bar {
    justify-content: right;
}

.flex-row.action-bar .btn.filled {
    margin-right: 5vw;
}

.flex-row.action-bar .flatbar {
    position: absolute;
    width: 100%;
    align-self: center;
}