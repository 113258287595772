.btn.filled .form-btn {
    padding: 0;
    cursor: pointer;
    border-width: 0;
    background-color: transparent;
}

.btn.filled .btn-text .form-btn {
    display: flex;
    align-items: center;
    justify-content: center;

    font-family: "neue-haas-grotesk-display", sans-serif;
    font-weight: 450;
    font-size: max(0.95em, 0.9vw);
    color: var(--color-dark);
}

.theme-dark .btn.filled .btn-text .form-btn {
    color: var(--color-light);
}