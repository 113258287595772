@import url('index.css');

/*   Style sheet developed by Scott Anderson   */

/* ----------------------- Body ----------------------------------------------- */

body,
html,
section {
  background-color: var(--color-light);
  margin: 0;
}

section {
  padding: 0 var(--page-padding) max(1em, 2vw);
  z-index: 1;
}

section.narrow {
  padding: 0 var(--page-padding-narrow) max(1em, 2vw);
}

@media screen and (max-width: 1200px) {
  section.narrow {
    padding: 0 var(--page-padding) max(1em, 2vw);
  }
}

/* --- Scroll Bar --- */

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  display: none;
}

::-webkit-scrollbar-track {
  background: var(--color-light);
}

::-webkit-scrollbar-thumb {
  background: var(--color-dark);
}

::-webkit-scrollbar-thumb:hover {
  background: var(--color-highlight);
}

/* --- Selection --- */

::selection {
  color: var(--color-dark);
  background: var(--color-dark);
}

::-moz-selection {
  color: var(--color-dark);
  background: var(--color-dark);
}


/* ----------------------- Main Elements ----------------------------------------------- */

/* --- Containers --- */

.container {
  position: relative;
  width: 100%;
  height: 100vh;
}