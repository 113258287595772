.about-header.container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: max(0em, 18vw);
}

.about-header.container section {
    gap: 20vw;
    width: stretch;
    overflow: hidden;
    align-items: flex-end;
}

.about-header h1 {
    margin: 0;
}

.about-header .info {
    width: fit-content;
}

.about-header .info p {
    width: max(20em, 15vw);
}

.portrait-image {
    transform: translateY(15%);
    width: max(40em, 32.5%);
    height: undefined;
    aspect-ratio: 947/1337;
}

.about-feature {
    margin-bottom: max(2em, 6vw);
}

.about-feature .title {
    margin-bottom: max(1em, 3vw);
}

@media screen and (max-width: 1800px) {
    .about-header.container section {
        gap: unset;
        justify-content: space-between;
    }
}

@media screen and (max-width: 1660px) {
    #education-entries {
        justify-content: center;
    }
}

@media screen and (max-width: 1450px) {
    .about-header .info .flex-row {
        flex-direction: column;
    }

    .about-header .info {
        gap: 1em;
    }

    .about-header .info p {
        min-width: 40vw;
    }
}

@media screen and (max-width: 1300px) {
    .about-header.container {
        justify-content: start;
    }
    
    .about-header.container section {
        margin-top: 10vw;
        flex-direction: column;
    }

    .portrait-image {
        margin-top: 2em;
        transform: translateY(0);
        width: 100%;
    }

    .about-header .info p {
        width: 100%;
    }
}

@media screen and (max-width: 1030px) {
    .education-entry {
        width: 100%;
    }
}