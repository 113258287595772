/*   Style sheet developed by Scott Anderson   */

/* ----------------------- Variables ----------------------------------------------- */

:root {
  --color-light: #ffffff;
  --color-dark: #1b1c1e;
  --color-gray: #a6a6a6;
  --color-highlight: #a0a0a0;
  --color-yellow: #efc04a;

  --color-blue: #8acbe2;
  --color-blue-dark: #2b58d7;
  --color-drop-shadow: #cecece;
  --color-notification-green: #23C552;
  --color-notification-red: #F84F31;

  --color-math-token: #b17cff;
  --color-math-var: #eb6d0d;

  --corner-radius: max(0.5em, 0.3vw);
  --page-padding: max(1.5em, 3.5vw);
  --page-padding-narrow: max(1.5em, 20vw);
  --scroll-reveal-rate: 0.5s;
  --home-anim-rate: 0.3s;
  --button-anim-rate: 0.15s;
}


/* ----------------------- Typography ----------------------------------------------- */

/* --- Neue-haas-grotesk 55 Roman (Title) --- */
h1 {
  margin: 0;
  font-family: "neue-haas-grotesk-display", sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: max(3em, 5.5vw);
  margin-bottom: max(0.5em, 5vw);
  color: var(--color-dark);
}

/* --- Neue-haas-grotesk 55 Roman (Sub-Title) --- */
h2 {
  margin: 0;
  font-family: "neue-haas-grotesk-display", sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: max(2em, 2.5vw);
  color: var(--color-dark);
}

/* --- Neue-haas-grotesk 55 Roman (Heading) --- */
h3 {
  margin: 0;
  font-family: "neue-haas-grotesk-display", sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: max(1.2em, 1vw);
  margin-bottom: max(0.5em, 1vw);
  color: var(--color-gray);
}

/* --- Neue-haas-grotesk 55 Roman (Sub-Heading) --- */
h4 {
  margin: 0;
  font-family: "neue-haas-grotesk-display", sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: max(1.5em, 1.8vw);
  color: var(--color-dark);
}

/* --- Neue-haas-grotesk 45 Light (Paragraphs) --- */
p {
  margin: 0;
  font-family: "neue-haas-grotesk-display", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: max(1em, 0.8vw);
  color: var(--color-dark);
}

p.medium {
  font-weight: 500;
  font-size: max(1.4em, 1.8vw);
}

p.large {
  font-weight: 500;
  font-size: max(1.6em, 2.5vw);
}

a { text-decoration: none; }

.theme-dark h1:not(.theme-dark .ignore-theme h1),
.theme-dark h3:not(.theme-dark .ignore-theme h3),
.theme-dark p:not(.theme-dark .ignore-theme p)  {
    color: var(--color-light);
}

/* ----------------------- Main Elements ----------------------------------------------- */

.no-select {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* --- Flex Row --- */

.flex-row {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 1em;
}

.flex-row.align-left {
  justify-content: left;
}

.flex-row.align-right {
  justify-content: right;
}

.flex-row.align-vertical {
  align-items: center;
}

.flex-row.align-opposite {
  justify-content: space-between;
}

.flex-row.wrap {
  flex-wrap: wrap;
}

.flex-row.no-gap {
  gap: 0;
}

.flex-row.narrow {
  gap: max(0.5em, 0.5vw);
}

.flex-row.normal {
  gap: max(1em, 2vw);
}

.flex-row.wide {
  gap: max(2em, 6vw);
}

/* --- Flex Column --- */

.flex-col {
  display: flex;
  flex-direction: column;
}

/* --- Shapes --- */

.basic-point {
  margin: auto 0;

  background-color: var(--color-dark);
  width: max(0.35em, 0.5vw);
  height: max(0.35em, 0.5vw);

  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
}

.basic-point.small {
  width: max(0.25em, 0.3vw);
  height: max(0.25em, 0.3vw);
}

.basic-point.large {
  width: max(0.4em, 0.6vw);
  height: max(0.4em, 0.6vw);
}

.basic-point.outline {
  border: 0.1vw solid var(--color-dark);
  background-color: transparent;
}

.basic-point.outline.small {
  border-width: 0.05vw;
}

.basic-point.outline.large {
  border-width: 0.15vw;
}

.basic-point.indicator {
  transition: transform var(--button-anim-rate) ease-in-out;
  position: absolute;
  bottom: 0;
  transform: scale(0);
}

.flatbar {
  width: 100%;
  height: max(0.05em, 0.1vw);
  background-color: var(--color-dark);
}

.flatbar.thin {
  /* height: max(0.04em, 0.08vw); */
  height: 1px;
  background-color: var(--color-gray);
}

.flatbar.thick {
  height: max(0.06em, 0.12vw);
}

.flatbar.animated {
  transition: transform 0.2s ease;
}

.arrow-link {
  position: absolute;
  left: 110%;
  align-self: center;

  transition: transform var(--button-anim-rate) ease-in-out;
  background-image: url("./assets/images/Arrow_Nav.png");
  background-size: cover;

  width: max(0.3em, 0.35vw);
  height: max(0.3em, 0.35vw);
  cursor: default;
  pointer-events: none;
}

/* --- Buttons --- */

.btn {
  cursor: pointer;
}

.btn .btn-text {
  transition: opacity var(--button-anim-rate) ease-in-out;
}

.btn:hover .btn-text.fade {
  opacity: 0.5;
}

/* --- Button Text --- */

.btn-text {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;

  position: relative;
  width: 100%;
  z-index: 2;

  margin: 0;
  font-family: "neue-haas-grotesk-display", sans-serif;
  font-weight: 450;
  font-style: normal;
  font-size: max(1em, 0.9vw);
  line-height: 0.9em;
  color: var(--color-dark);
}

.theme-dark .btn-text:not(.theme-dark .ignore-theme .btn-text) {
  color: var(--color-light);
}