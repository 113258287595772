.title-box-home {
    width: 100%;
    height: 100%;
    position: absolute;
    padding: var(--page-padding) max(1em, 2vw);
}

.title-box-home h1 {
    line-height: max(0.8em, 4vw);
}

.about-container {
    width: max(30em, 45%);
    margin-left: auto;
    padding: max(5em, 8vw) 0 max(5em, 3.5vw);
}

@media screen and (max-width: 750px) {
    .about-container {
        width: 100%;
    }
}