footer {
    padding: max(4em, 10vw) var(--page-padding) max(1em, 2vw);
}

.footer-group {
    gap: max(1.5em, 5vw);
}

.footer-group .info-container {
    width: max(25em, 45%);
    margin-left: auto;
    gap: max(1em, 2vw);
    padding-bottom: max(1.5em, 3.5vw);
}

.footer-group .nav-bar {
    align-items: baseline;
}

@media screen and (min-width: 1200px) {
    .footer-group .flatbar.thin {
        display: none;
    }
}

@media screen and (max-width: 1200px) {
    .footer-group .footer-content {
        flex-direction: column;
        gap: max(1.5em, 5vw);
    }

    .footer-group .footer-content #group_0 {
        order: 1;
    }

    .footer-group .info-container {
        width: 100%;
        margin-left: unset;
        padding-bottom: 0;
    }
}

@media screen and (max-width: 750px) {
    .footer-group .nav-bar {
        flex-direction: column;
        align-items: center;
        gap: max(1em, 3vw);
    }

    .footer-group .nav-bar .flex-row {
        flex-direction: column;
        align-items: center;
    }
}

@media screen and (max-width: 500px) {
    .footer-group .footer-content #group_0,
    .footer-group .footer-content #group_1 {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .footer-group .footer-content #group_0 .btn.link {
        align-self: center;
    }
}