.education-entry {
    width: max(28em, 25vw);
}

.education-entry .info {
    margin-top: max(0.5em, 1vw);
}

.education-entry .info .flex-col {
    gap: max(0.5em, 0.5vw);
}

.education-entry h3.description {
    color: var(--color-dark);
}

.education-entry h4 {
    line-height: 90%;
}