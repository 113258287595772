.icon {
    width: max(2.7em, 3vw);
    height: max(2.7em, 3vw);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

.icon.small {
    width: max(1.5em, 1.5vw);
    height: max(1.5em, 1.5vw);
}

.icon.medium {
    width: max(2em, 2.5vw);
    height: max(2em, 2.5vw);
}

.icon.large {
    width: max(4em, 4.5vw);
    height: max(4em, 4.5vw);
}

.icon.invert {
    filter: invert();
}