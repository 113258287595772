.btn.flip {
    width: fit-content;
    height: fit-content;

    display: flex;
    flex-direction: column;
    justify-content: top;

    overflow: hidden;
}

.btn.flip:hover .wrapper {
    transform: translateY(-100%);
}

.btn.flip .wrapper {
    transition: transform ease-in-out 0.3s;
    position: relative;
}

.btn.flip .btn-text {
    padding: max(0.1em, 0.1vw) 0;
    width: 100%;
}

.btn.flip .reveal {
    position: absolute;
}