.btn.link {
    position: relative;
    width: fit-content;
}

.btn.link .flatbar {
    transition: transform var(--button-anim-rate) ease-in-out;
    background-color: var(--color-gray);
    transform: scaleX(0);
}

.btn.link:hover .flatbar {
    transform: scaleX(1);
}