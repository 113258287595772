.contact {
    gap: max(2em, 10vw);
}

.contact .details {
    min-width: 30%;
}

.contact .details .button-groups {
    gap: max(3em, 3vw);
    margin-top: max(1em, 1vw);
}

.contact p.medium {
    margin-bottom: max(2em, 3vw);
}

@media screen and (min-width: 1200px) {
    .contact .alt-header {
        display: none;
    }
}

@media screen and (max-width: 1200px) {
    .contact {
        flex-direction: column;
    }

    .contact .main-header {
        display: none;
    }

    .contact .details {
        order: -1;
    }

    .contact .details .button-groups {
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: left;
        flex-direction: row-reverse;
    }

    .contact .details .button-groups .icon {
        display: none;
    }
}

@media screen and (max-width: 500px) {
    .contact .details .button-groups {
        gap: max(2em, 3vw);
        flex-direction: column;
    }
}