header {
    padding: max(1em, 2vw) var(--page-padding);
    z-index: 2;
    top: 0;
}

@media screen and (max-width: 750px) {
    header .ignore-theme {
        display: none;
    }
}