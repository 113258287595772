.preview-container {
    pointer-events: none;
    position: absolute;
    width: 20vw;
    height: undefined;
    aspect-ratio: 16/9;
    z-index: 2;
    transform: translate(-50%, -50%);
}

.preview-container .image-box {
    position: absolute;
    height: 100%;
    width: 100%;
    overflow: hidden;
    transition: transform 0.5s ease;
}

.preview-container .image-box img {
    transition: transform 0.5s ease;
}