.btn.nav {
    position: relative;
    width: fit-content;
    
    display: flex;
    justify-content: center;
    padding: max(0.1em, 0.1vw) 0 max(0.8em, 1vw);
}

.btn.nav:hover .basic-point.indicator {
    transform: scale(1);
}

.theme-dark .btn.nav .basic-point {
    background-color: var(--color-light);
}