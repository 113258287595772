.projects-container {
    padding: max(1.5em, 3.5vw) 0;
}

.project {
    width: 100%;
    padding: max(0.5em, 0.8vw) 0;
    border-bottom: max(0em, 0.1vw) solid var(--color-dark);
}

.project h3 {
    margin-bottom: unset;
}

.project .description {
    color: var(--color-dark);
}

.project * {
    width: 100%;
}

@media screen and (max-width: 1200px) {
    .project {
        flex-direction: column;
    }

    .project h3:not(.description) {
        margin-bottom: -0.8em;
    }

    .project .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}