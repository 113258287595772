.btn.filled {
    position: relative;
    width: fit-content;
    height: fit-content;
    padding: max(0.3em, 0.5vw) max(0.6em, 1vw);

    background-color: var(--color-light);
    filter: drop-shadow(0 0.3vw 0.6vw var(--color-drop-shadow));
    border-radius: 50px;

    display: flex;
    justify-content: center;
    overflow: hidden;
}

.btn.filled .fill-element {
    transition: top ease-in-out var(--button-anim-rate);
    position: absolute;
    top: 105%;

    width: 105%;
    height: 105%;
    border-radius: inherit;
    background-color: var(--color-dark);
}

.btn.filled:hover .fill-element {
    top: 0;
}

.btn.filled:hover .btn-text,
.btn.filled:hover .btn-text .form-btn {
    transition: color var(--button-anim-rate);
    color: var(--color-light);
}

.theme-dark .ignore-theme .btn.filled {
    filter: none;
}

.theme-dark .btn.filled:not(.theme-dark .ignore-theme .btn.filled) {
    background-color: var(--color-dark);
    /* filter: drop-shadow(0 0.3vw 0.6vw var(--color-drop-shadow)); */
}

.theme-dark .btn.filled .fill-element:not(.theme-dark .ignore-theme .btn.filled .fill-element) {
    background-color: var(--color-light);
}

.theme-dark .btn.filled:hover:not(.theme-dark .ignore-theme .btn.filled) .btn-text,
.theme-dark .btn.filled:hover:not(.theme-dark .ignore-theme .btn.filled) .btn-text .form-btn {
    color: var(--color-dark);
}