.form .form-field {
    width: 100%;
}

.form .content {
    width: 100%;
    padding: 1vw 0;
    justify-content: left;
}

.form .data {
    width: 100%;
}

.form .label h4 {
    transition: opacity 0.2s ease-in-out;
    font-size: max(1.2em, 1.5vw);
}

.form .contact-line.not-empty .label h4 {
    opacity: 0.3;
}

.form .field {
    border: 0;
    width: 100%;
    background: transparent;
    color: var(--color-dark);

    font-family: "neue-haas-grotesk-display", sans-serif;
    font-weight: 500;
    font-size: max(1em, 1vw);
}

.form .field:focus {
    outline: 0;
    border: 0;
}

.form .field::placeholder {
    color: var(--color-gray);
}

.form textarea.field {
    resize: none;
    height: max(8em, 8vw);
}

.form .error-prompt h3 {
    color: var(--color-notification-red);
    font-size: max(0.9em, 0.8vw);
    display: none;
}

.form .contact-input.has-error .error-prompt h3 {
    display: block;
}